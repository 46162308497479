import React from 'react'

const EightTwentyWestMacArthur = (props) => {
    return (
        <div>
            820 West MacArthur Placeholder Page
        </div>
    )
}

export default EightTwentyWestMacArthur;